import ProgressComponent from 'common/ProgressComponent'
import React, { useState } from 'react'
import { Button, Col, Form, Row, Stack } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setChoice } from 'redux/actions/choice'
import { Link, useNavigate } from 'react-router-dom'
import CustomNavigationButtons from "../../../../common/CustomNavigationButtons"

export default function StepTwoPageOneAndHalf() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [progressPercentage, setProgressPercentage] = useState(0)

  const handleProgressUpdate = percentage => {
    // Update the state with the new progress percentage
    setProgressPercentage(percentage)
  }

  return (
    <>
      <section className='u-background--color-info-1-3 u-padding--b-35 u-scrollbar--hide u-min-height--100vh-124 step-bg'>
        <Form>
          <div className='u-border-radius--20 u-padding--y-49 u-padding--x-32 bg-white h-100 u-box-shadow--color-info-1-4-80p'>
            <ProgressComponent
              step={2}
              subStep={1}
              onProgressUpdate={handleProgressUpdate}
            />

            <div className='u-margin--t-36'>
              <Row className='gap-4 gap-lg-0'>
                <Col lg={12} style={{ marginBottom: '3rem' }}>
                  <Stack
                    direction='horizontal'
                    className='align-items-center h-100'
                  >
                    <p className='u-font-size--14 fw-semibold u-font-size--14-bp-576 lh-lg u-margin--b-26 u-padding--e-37'>
                      Step three of our 7 Step Process is all about
                      understanding your Financial Health. So, it’s time for the
                      fun stuff. Over the next few pages, we’ll roll up our
                      sleeves, breakout our pens and dive into your balance
                      sheet and cash flow statement. Don’t worry, it’s not as
                      bad as it sounds. We have two tracks, a High Level and a
                      Detailed track. It’s your choice!
                    </p>
                  </Stack>
                </Col>
              </Row>
              <Row className='gap-4 gap-lg-0'>
                <Col lg={12}>
                  <Stack
                    direction='horizontal'
                    className='align-items-center h-100'
                  >
                    <p className='u-font-size--14 u-font-size--14-bp-576 lh-lg u-margin--b-26 u-padding--e-37'>
                      In our High Level track, the simple version, you will list
                      out the rough details and numbers for each financial
                      category. Sort of like building your financial plan on the
                      back of a napkin. Quick and easy. We’ll take these numbers
                      and build a high level balance sheet without too much
                      detail. It will be insightful, but we’ve found that people
                      who take the time to do the details tend to have more
                      success in changing their financial life. We get it
                      crunching numbers is fun, and staying at high level is
                      easy. If you’re really after results, the detailed path
                      might be more rewarding.
                    </p>
                  </Stack>
                </Col>
                <Col lg={12} style={{ marginBottom: '3rem' }}>
                  <Button
                    onClick={() => {
                      dispatch(setChoice('simple'))
                      // navigate('/steps/step-two/page-two')
                      navigate('/steps/step-two/page-two?choice=simple');
                    }}
                    className=' u-background--color-orange-1-1 u-border-color--color-orange-1-1 u-padding--x-25 u-height--51 u-font-size--15 fw-medium text-uppercase d-flex align-items-center justify-content-center text-center rounded-3'
                  >
                    Simple Track
                  </Button>
                </Col>
              </Row>
              <Row className='gap-4 gap-lg-0 mt-3'>
                <Col lg={12}>
                  <Stack
                    direction='horizontal'
                    className='align-items-center h-100'
                  >
                    <p className='u-font-size--14 u-font-size--14-bp-576 lh-lg u-margin--b-26 u-padding--e-37'>
                      On the other hand, we have a detailed track. This path
                      will take more time. We’ll ask for more detail, but in the
                      end, you’ll have a complete financial picture. You’ll have
                      a great idea of what your Financial Health looks like
                      regardless of whether it’s currently good or bad. By
                      choosing the detailed path, you’re giving yourself the
                      opportunity to better understand your financial strengths
                      and your weaknesses. By choosing this route you’re taking
                      a big step forward in deciding to make the necessary
                      changes to reach your financial goals.
                    </p>
                  </Stack>
                </Col>
                <Col lg={12}>
                  <Button
                    onClick={() => {
                      dispatch(setChoice('detailed'))
                      navigate('/steps/step-two/page-three?choice=detailed')
                    }}
                    className='u-background--color-orange-1-1 u-border-color--color-orange-1-1 u-padding--x-25 u-height--51 u-font-size--15 fw-medium text-uppercase d-flex align-items-center justify-content-center text-center rounded-3'
                  >
                    Detailed Track
                  </Button>
                </Col>
              </Row>
              
            </div>
          </div>
          {/* <Row className='u-margin--t-35 gap-4 gap-lg-0'>
                <Col lg={6}>
                  <Button
                    as={Link}
                    to='/steps/step-two/page-one'
                    variant='outline-secondary'
                    className='u-width--124 u-padding--x-25 u-height--51 u-font-size--15 fw-medium text-uppercase border-2 u-border-color--color-success-1-2 rounded-3 d-flex align-items-center justify-content-center text-center'
                  >
                    Previous
                  </Button>
                </Col>
                <Col lg={6}></Col>
              </Row> */}
            <CustomNavigationButtons
              previousLink='/steps/step-two/page-one'
              loading={false} 
              showPrevious={true}
              showSkip={false}
              showSaveAndNext={false}
            />
        </Form>
      </section>
    </>
  )
}
