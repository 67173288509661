import { combineReducers } from 'redux'
import auth from './auth'
import message from './message'
import users from './users'
import states from './states'
import steps from './steps'
import notification from './notification'
import global from './global'
import choiceReducer from './choice'

const reducers = combineReducers({
  auth,
  message,
  users,
  states,
  steps,
  notification,
  global,
  choiceReducer
})

export default reducers
