import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Stack from 'react-bootstrap/Stack'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import ProgressMark from 'assets/images/steps/step-one/page-one/header/progress-mark.svg'
import "./ProgressComponent.css"
const ProgressComponent = props => {
  const [currentStep, setCurrentStep] = useState(props.step)
  const [currentSubStep, setCurrentSubStep] = useState(props.subStep)
  const [progressPercentage, setProgressPercentage] = useState(0)

  useEffect(() => {
    if (currentStep != undefined && currentSubStep != undefined) {
      if (currentStep === 1) {
        if (currentSubStep === 2) {
          setProgressPercentage(3)
        } else if (currentSubStep === 3) {
          setProgressPercentage(6)
        } else if (currentSubStep === 4) {
          setProgressPercentage(8)
        } else if (currentSubStep === 5) {
          setProgressPercentage(10)
        } else if (currentSubStep === 6) {
          setProgressPercentage(12)
        } else if (currentSubStep === 7) {
          setProgressPercentage(13)
        } else if (currentSubStep === 8) {
          setProgressPercentage(15)
        } else {
          setProgressPercentage(1)
        }
      } else if (currentStep === 2) {
        if (currentSubStep === 2) {
          setProgressPercentage(21)
        } else if (currentSubStep === 3) {
          setProgressPercentage(24)
        } else if (currentSubStep === 4) {
          setProgressPercentage(27)
        } else if (currentSubStep === 5) {
          setProgressPercentage(30)
        } else {
          setProgressPercentage(18)
        }
      } else if (currentStep === 3) {
        if (currentSubStep === 2) {
          setProgressPercentage(38)
        } else if (currentSubStep === 3) {
          setProgressPercentage(42)
        } else if (currentSubStep === 4) {
          setProgressPercentage(46)
        } else {
          setProgressPercentage(34)
        }
      } else if (currentStep === 4) {
        if (currentSubStep === 1) {
          setProgressPercentage(50)
        }
      } else if (currentStep === 5) {
        if (currentSubStep === 1) {
          setProgressPercentage(66)
        }
      } else if (currentStep === 6) {
        if (currentSubStep === 2) {
          setProgressPercentage(88)
        } else if (currentSubStep === 3) {
          setProgressPercentage(94)
        } else {
          setProgressPercentage(82)
        }
      } else {
        setProgressPercentage(100)
      }
    }
  }, [props.step, props.subStep])

  //Call the updateProgress function to pass the progressPercentage back
  useEffect(() => {
    props.onProgressUpdate(progressPercentage)
  }, [progressPercentage])

  return (
    <div className='position-relative u-padding--t-62 p-bar'>
      <Stack
        direction='horizontal'
        className='step-line align-items-center position-absolute u-bottom--m-9 end-0 start-0 z-2 u-margin--x-m-66'
      >
        <Stack className='align-items-start u-padding--x-66'>
          <h1 className='u-font-size--14 fw-semibold text-primary u-padding--b-31'>
            Step 1
          </h1>
          <a
            className='u-width--23 u-height--23 rounded-circle d-flex align-items-center justify-content-center overflow-hidden u-background--color-primary-1-8 border border-primary u-font-size--12 fw-medium text-white'
            href='/steps/step-one/page-one'
            style={{ textDecoration: 'none' }}
          >
            1
          </a>
        </Stack>
        <Stack className='align-items-center u-padding--x-66'>
          <h1
            className={`u-font-size--14 ${currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'fw-semibold text-primary' : 'fw-normal u-text-color--color-white-1-1'} u-padding--b-31`}
          >
            Step 2
          </h1>
          <a
            href='/steps/step-two/page-one'
            style={{ textDecoration: 'none' }}
            className={`u-width--23 u-height--23 rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'u-background--color-primary-1-8' : 'u-background--color-white-1-2'} border u-border-color--color-white-1-1 u-font-size--12 fw-medium u-text-color--color-white-1-1`}
          >
            2
          </a>
        </Stack>
        <Stack className='align-items-center u-padding--x-66'>
          <h1
            className={`u-font-size--14 ${currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'fw-semibold text-primary' : 'fw-normal u-text-color--color-white-1-1'} u-padding--b-31`}
          >
            Step 3
          </h1>
          <a
            href='/steps/step-three/page-one'
            style={{ textDecoration: 'none' }}
            className={`u-width--23 u-height--23 rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'u-background--color-primary-1-8' : 'u-background--color-white-1-2'} border u-border-color--color-white-1-1 u-font-size--12 fw-medium u-text-color--color-white-1-1`}
          >
            3
          </a>
        </Stack>
        <Stack className='align-items-center u-padding--x-66'>
          <h1
            className={`u-font-size--14 ${currentStep === 4 || currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'fw-semibold text-primary' : 'fw-normal u-text-color--color-white-1-1'} u-padding--b-31`}
          >
            Step 4
          </h1>
          <a
            href='/steps/step-four'
            style={{ textDecoration: 'none' }}
            className={`u-width--23 u-height--23 rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${currentStep === 4 || currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'u-background--color-primary-1-8' : 'u-background--color-white-1-2'} border u-border-color--color-white-1-1 u-font-size--12 fw-medium u-text-color--color-white-1-1`}
          >
            4
          </a>
        </Stack>
        <Stack className='align-items-center u-padding--x-66'>
          <h1
            className={`u-font-size--14 ${currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'fw-semibold text-primary' : 'fw-normal u-text-color--color-white-1-1'} u-padding--b-31`}
          >
            Step 5
          </h1>
          <a
            href='/steps/step-five'
            style={{ textDecoration: 'none' }}
            className={`u-width--23 u-height--23 rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${currentStep === 5 || currentStep === 6 || currentStep === 7 ? 'u-background--color-primary-1-8' : 'u-background--color-white-1-2'} border u-border-color--color-white-1-1 u-font-size--12 fw-medium u-text-color--color-white-1-1`}
          >
            5
          </a>
        </Stack>
        <Stack className='align-items-center u-padding--x-66'>
          <h1
            className={`u-font-size--14 ${currentStep === 6 || currentStep === 7 ? 'fw-semibold text-primary' : 'fw-normal u-text-color--color-white-1-1'} u-padding--b-31`}
          >
            Step 6
          </h1>
          <a
            href='/steps/step-six/page-one'
            style={{ textDecoration: 'none' }}
            className={`u-width--23 u-height--23 rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${currentStep === 6 || currentStep === 7 ? 'u-background--color-primary-1-8' : 'u-background--color-white-1-2'} border u-border-color--color-white-1-1 u-font-size--12 fw-medium u-text-color--color-white-1-1`}
          >
            6
          </a>
        </Stack>
        <Stack className='align-items-end u-padding--x-66'>
          <h1
            className={`u-font-size--14 ${currentStep === 7 ? 'fw-semibold text-primary' : 'fw-normal u-text-color--color-white-1-1'} u-padding--b-31`}
          >
            Step 7
          </h1>
          <a
            href='/steps/step-seven'
            style={{ textDecoration: 'none' }}
            className={`u-width--23 u-height--23 rounded-circle d-flex align-items-center justify-content-center overflow-hidden ${currentStep === 7 ? 'u-background--color-primary-1-8' : 'u-background--color-white-1-2'} border u-border-color--color-white-1-1 u-font-size--12 fw-medium u-text-color--color-white-1-1`}
          >
            7
          </a>
        </Stack>
      </Stack>
      <Stack
        gap={2}
        className='step-value align-items-center position-absolute u-bottom--m-6 z-2'
        style={{ left: `${progressPercentage - 2}%` }}
      >
        <div className='d-flex align-items-center justify-content-center u-max-width--26 u-height--22 u-border-radius--3 u-background--color-info-1-5 u-font-size--10 fw-medium u-padding--7 u-text-color--color-primary-1-8 text-center border u-border-color--color-primary-1-8'>
          {progressPercentage}%
        </div>

        <Button className='u-max-width--16 u-flex--16 d-flex align-items-center justify-content-center overflow-hidden bg-transparent p-0 border-0'>
          <Image
            src={ProgressMark}
            alt='Progress mark'
            className='w-auto h-100'
          />
        </Button>
      </Stack>
      <ProgressBar
        className='s-progress s-progress--multi-steps-form'
        now={progressPercentage}
      />
    </div>
  )
}

export default ProgressComponent
