import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Layout from 'components/Layout/index'

const AnonymousRoutes = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <section className='u-padding--t-92 u-padding--b-35 u-scrollbar--hide u-min-height--100vh-124 d-flex align-items-center justify-content-center'>
            <Spinner animation='border' variant='success' />
          </section>
        }
      >
        <Outlet />
      </Suspense>
    </Layout>
  )
}

export default AnonymousRoutes
