import React,{useEffect,useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import { Link } from 'react-router-dom'
import Stack from 'react-bootstrap/Stack'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import ArkosLogo from 'assets/images/home/footer/arkos-logo.svg'
import Facebook from 'assets/images/home/footer/facebook.svg'
import Twitter from 'assets/images/home/footer/twitter.svg'
import Instagram from 'assets/images/home/footer/instagram.svg'
import Youtube from 'assets/images/home/footer/youtube.svg'
import LinkedIn from 'assets/images/home/footer/linkedin.svg'



const Footer = () => {
  const dispatch = useDispatch()
  const { listSettings } = useSelector(state => state.global);
  const getConfigValue = (configKey) => {
    const setting = listSettings.find(setting => setting.configKey === configKey);
    return setting ? setting.configValue : '';
  };
  
  const youtubeUrl = getConfigValue('youtubeUrl');
  const facebookUrl = getConfigValue('facebookUrl');
  const linkedinUrl = getConfigValue('linkedinUrl');
  const twitterUrl = getConfigValue('twitterUrl');
  const instagramUrl = getConfigValue('instagramUrl');
  return (
    <>
      <footer className='u-background--color-black-1-1 u-padding--y-38'
        style={{
          //position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          zIndex: 900,
        }}
      >
        <Container>
          <Row className='gy-4 gy-lg-0'>
            <Col
              md={6}
              className='d-flex align-items-center justify-content-center flex-column d-md-block'
            >
              <Link
                to='/'
                className='u-max-width--130 u-flex--130 d-flex align-items-center justify-content-center overflow-hidden u-margin--b-5'
                style={{position: "relative"}}
              >
                <Image src={ArkosLogo} alt='Arkos logo' />
              </Link>
              <p className='u-font-size--14 u-font-size--14-bp-576 text-white'>
                © 2023 arkosglobal.com. All Rights Reserved
              </p>
            </Col>
            <Col
              md={6}
              className='d-flex align-items-center align-items-md-end flex-column'
            >
              <ListGroup
                horizontal
                className='u-margin--x-m-8 s-list-group--footer mb-4'
              >
                <ListGroup.Item className='p-0 border-0 bg-transparent'>
                <a
                  href="/terms-and-conditions"
                  className='u-font-size--14 u-font-size--14-bp-576 text-white text-decoration-none border-end border-white px-2 lh-1'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Terms & conditions
                </a>
                </ListGroup.Item>
                <ListGroup.Item className='p-0 border-0 bg-transparent'>
                  <a
                  href="/privacy-policy"
                  className='u-font-size--14 u-font-size--14-bp-576 text-white text-decoration-none border-end border-white px-2 lh-1'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Privacy policy
                </a>
                </ListGroup.Item>
              </ListGroup>

              <Stack
                direction='horizontal'
                gap={4}
                className='justify-content-center justify-content-md-end'
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id='facebook'>Follow us on facebook</Tooltip>
                  }
                >
                  <Link
                    to={facebookUrl}
                    target='_blank'
                    className='u-height--22 d-flex align-items-center justify-content-center overflow-hidden'
                  >
                    <Image
                      src={Facebook}
                      alt='Facebook'
                      className='w-auto h-100'
                    />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id='twitter'>Follow us on twitter</Tooltip>}
                >
                  <Link
                    to={twitterUrl}
                    target='_blank'
                    className='u-height--22 d-flex align-items-center justify-content-center overflow-hidden'
                  >
                    <Image
                      src={Twitter}
                      alt='Twitter'
                      className='w-auto h-100'
                    />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id='instagram'>Follow us on instagram</Tooltip>
                  }
                >
                  <Link 
                  to={instagramUrl}
                  className='u-height--22 d-flex align-items-center justify-content-center overflow-hidden'>
                    <Image
                      src={Instagram}
                      alt='Instagram'
                      className='w-auto h-100'
                    />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={<Tooltip id='youtube'>Follow us on youtube</Tooltip>}
                >
                  <Link
                    to={youtubeUrl}
                    target='_blank'
                    className='u-height--22 d-flex align-items-center justify-content-center overflow-hidden'
                  >
                    <Image
                      src={Youtube}
                      alt='Youtube'
                      className='w-auto h-100'
                    />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id='linkedin'>Follow us on linkedin</Tooltip>
                  }
                >
                  <Link
                    to={linkedinUrl}
                    target='_blank'
                    className='u-height--22 d-flex align-items-center justify-content-center overflow-hidden'
                  >
                    <Image
                      src={LinkedIn}
                      alt='LinkedIn'
                      className='w-auto h-100'
                    />
                  </Link>
                </OverlayTrigger>
              </Stack>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
