import { RETRIEVE_STATES } from '../actions/types'

const initialState = []

const stateReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETRIEVE_STATES:
      return payload

    default:
      return state
  }
}

export default stateReducer
