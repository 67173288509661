// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-navigation-buttons-container {
    position: sticky;
    bottom: 5px;
    width: 100%;
    background-color: white; 
    z-index: 1000; 
  }
  `, "",{"version":3,"sources":["webpack://./src/common/CustomNavigationButtons.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,aAAa;EACf","sourcesContent":[".custom-navigation-buttons-container {\n    position: sticky;\n    bottom: 5px;\n    width: 100%;\n    background-color: white; \n    z-index: 1000; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
