import React, { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Layout from 'components/Layout/index'

const PublicRoutes = ({ isLoggedIn, stepAt, subStepAt }) => {
  let stepValue = '/steps/step-one/'
  let subStepValue = stepValue + 'page-one'
  if (stepAt === '4' || stepAt === '5') {
    subStepAt = '0'
  }
  switch (stepAt) {
    case '1':
      stepValue = '/steps/step-one/'
      break
    case '2':
      stepValue = '/steps/step-two/'
      break
    case '3':
      stepValue = '/steps/step-three/'
      break
    case '4':
      stepValue = '/steps/step-four/'
      break
    case '5':
      stepValue = '/steps/step-five/'
      break
    case '6':
      stepValue = '/steps/step-six/'
      break
    case '7':
      stepValue = '/steps/step-seven/'
      break
    default:
      stepValue = '/steps/step-one/'
  }

  switch (subStepAt) {
    case '1':
      subStepValue = stepValue + 'page-one'
      break
    case '2':
      subStepValue = stepValue + 'page-two'
      break
    case '3':
      subStepValue = stepValue + 'page-three'
      break
    case '4':
      subStepValue = stepValue + 'page-four'
      break
    case '5':
      subStepValue = stepValue + 'page-five'
      break
    case '6':
      subStepValue = stepValue + 'page-six'
      break
    case '7':
      subStepValue = stepValue + 'page-seven'
      break
    case '8':
      subStepValue = stepValue + 'page-eight'
      break
    default:
      subStepValue = stepValue
      break
  }

  if (subStepValue === '/steps/step-one/') {
    subStepValue = '/steps/step-one/page-one'
  }

  return isLoggedIn ? (
    <Navigate to={subStepValue} replace />
  ) : (
    <Layout>
      <Suspense
        fallback={
          <section className='u-scrollbar--hide u-min-height--100vh-124 d-flex align-items-center justify-content-center flex-grow-1'>
            <Spinner animation='border' variant='success' />
          </section> 
        }
      >
        <Outlet />
      </Suspense>
    </Layout>
  )
}

export default PublicRoutes
