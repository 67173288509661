export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT = 'LOGOUT'

export const SET_MESSAGE = 'SET_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'

export const RETRIEVE_STATES = 'RETREIVE_STATES'

export const RETRIEVE_LOGGEDIN_USER = 'RETRIEVE_LOGGEDIN_USER'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

export const UPDATE_STEP = 'UPDATE_STEP'

export const CREATE_ACTION_ITEM = 'CREATE_ACTION_ITEM'
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST'

export const GET_SETTINGS = 'GET_SETTINGS'
export const SET_CHOICE = 'SET_CHOICE'
