import { useEffect } from 'react'

export const tawkToLoadScripts = async () => {
  // var Tawk_API = Tawk_API || {};

  // var Tawk_LoadStart = new Date();

  await (function () {
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0]
    s1.async = true
    s1.src = 'https://embed.tawk.to/6583ea6670c9f2407f822359/1hi5kn8o1'
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
  })()
}

const ChatBot = () => {
  useEffect(() => {
    tawkToLoadScripts()
  }, [])
  return <div></div>
}
export default ChatBot
