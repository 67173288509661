import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Image from 'react-bootstrap/Image'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-router-dom'

import Dashboard from 'assets/images/left-sidebar/dashboard.svg'
import DashboardActive from 'assets/images/left-sidebar/dashboard-active.svg'
import ManageData from 'assets/images/left-sidebar/manage-data.svg'
import ManageDataActive from 'assets/images/left-sidebar/manage-data-active.svg'
import FinancialSummary from 'assets/images/left-sidebar/financial-summary.svg'
import FinancialSummaryActive from 'assets/images/left-sidebar/financial-summary-active.svg'
import { useLocation } from 'react-router-dom'

const LeftSidebar = props => {
  const location = useLocation()
  const { className } = props

  return (
    <div className={className}>
      <Nav
        activeKey={location.pathname}
        className='flex-column s-nav s-nav--left-nav-sidebar'
      >
        <NavLink
          to='/dashboard'
          className='s-nav__item u-font-size--14 u-font-size--14-bp-576 fw-semibold text-secondary u-padding--11 u-padding--s-30 text-decoration-none d-flex align-items-center u-gap--10'
        >
          <i className='u-max-width--15 d-flex align-items-center justify-content-center overflow-hidden bg-transparent p-0 border-0'>
            <Image
              src={Dashboard}
              alt='Dashboard'
              className='w-100 h-auto s-nav__initial-state'
            />
            <Image
              src={DashboardActive}
              alt='Dashboard active'
              className='w-100 h-auto s-nav__active-state'
            />
          </i>
          Dashboard
        </NavLink>
        <div className='s-nav__item'>
          <Accordion className='bg-transparent'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <i className='u-max-width--15 u-flex--15 d-flex align-items-center justify-content-center overflow-hidden bg-transparent p-0 border-0'>
                  <Image
                    src={ManageData}
                    alt='Manage data'
                    className='w-100 h-auto s-nav__initial-state'
                  />
                  <Image
                    src={ManageDataActive}
                    alt='Manage data active'
                    className='w-100 h-auto s-nav__active-state'
                  />
                </i>
                Manage Data
              </Accordion.Header>
              <Accordion.Body className='px-0 u-padding--y-12'>
                <Nav
                  activeKey={location.pathname}
                  className='flex-column s-nav s-nav--left-nav-sidebar'
                >
                  <NavLink
                    to='/who-are-we-financially'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Who are you Financially
                  </NavLink>
                  <NavLink
                    to='/own-your-future'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Own Your Future
                  </NavLink>
                  <NavLink
                    to='/what-is-your-financial-health'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    What is Your Financial Health
                  </NavLink>
                  <NavLink
                    to='/tell-your-money-where-to-go'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Tell Your Money Where To Go
                  </NavLink>
                  <NavLink
                    to='/build-your-roadmap'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Build Your Roadmap
                  </NavLink>
                  <NavLink
                    to='/your-financial-world-on-one-page'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Your financial world on one page
                  </NavLink>
                  <NavLink
                    to='/thing-you-donot-want-to-thing-about'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Things You Don’t Want To Think About
                  </NavLink>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className='s-nav__item'>
          <Accordion className='bg-transparent'>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>
                <i className='u-max-width--15 u-flex--15 d-flex align-items-center justify-content-center overflow-hidden bg-transparent p-0 border-0'>
                  <Image
                    src={FinancialSummary}
                    alt='Financial summary'
                    className='w-100 h-auto s-nav__initial-state'
                  />
                  <Image
                    src={FinancialSummaryActive}
                    alt='Financial summary active'
                    className='w-100 h-auto s-nav__active-state'
                  />
                </i>
                Results
              </Accordion.Header>
              <Accordion.Body className='px-0 u-padding--y-12'>
                <Nav
                  activeKey={location.pathname}
                  className='flex-column s-nav s-nav--left-nav-sidebar'
                >
                  <NavLink
                    to='/steps/step-one/page-six'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Wealth Language
                  </NavLink>
                  <NavLink
                    to='/steps/step-one/page-eight'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Risk Score
                  </NavLink>
                  <NavLink
                    to='/steps/step-four'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Financial Health
                  </NavLink>
                  <NavLink
                    to='/steps/step-five'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Top Priorities
                  </NavLink>
                  <NavLink
                    to='/steps/step-seven'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Score Card
                  </NavLink>
                  <NavLink
                    to='/steps/step-six/page-three'
                    className='u-font-size--13 fw-normal text-secondary u-padding--s-55 u-padding--y-12 pe-0 text-decoration-none'
                  >
                    Key Financial Contacts
                  </NavLink>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        {/* <NavLink
          to="/financial-summary"
          className="s-nav__item u-font-size--14 u-font-size--14-bp-576 fw-semibold text-secondary u-padding--11 u-padding--s-30 text-decoration-none d-flex align-items-center u-gap--10"
        >
          <i className="u-max-width--15 d-flex align-items-center justify-content-center overflow-hidden bg-transparent p-0 border-0">
            <Image
              src={FinancialSummary}
              alt="Financial summary"
              className="w-100 h-auto s-nav__initial-state"
            />
            <Image
              src={FinancialSummaryActive}
              alt="Financial summary active"
              className="w-100 h-auto s-nav__active-state"
            />
          </i>
          Financial Summary
        </NavLink> */}
      </Nav>
    </div>
  )
}

export default LeftSidebar
