import { GET_NOTIFICATION_LIST } from '../actions/types'

const initialState = {}

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_NOTIFICATION_LIST:
      return payload

    default:
      return state
  }
}

export default notificationReducer
