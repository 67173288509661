import React, { Suspense } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import Layout from 'components/Layout/index'

const ProtectedRoutes = ({ isLoggedIn }) => {
  const location = useLocation()
  const currentUrl = location.pathname
  const leftSidebarVisibleUrls = [
    '/dashboard',
    '/who-are-we-financially',
    '/own-your-future',
    '/what-is-your-financial-health',
    '/tell-your-money-where-to-go',
    '/your-financial-world-on-one-page',
    '/thing-you-donot-want-to-thing-about',
    '/financial-summary',
    '/my-profile',
    '/edit-my-profile',
    '/step1result'
  ]
  const isLeftSidebarVisibleUrls = leftSidebarVisibleUrls.includes(currentUrl)
  return isLoggedIn ? (
    <Layout>
      <Suspense
        fallback={
          !isLeftSidebarVisibleUrls ? (
            // <section className='u-padding--t-92 u-padding--b-35 u-scrollbar--hide u-min-height--100vh-124 d-flex align-items-center justify-content-center'>
            <section className='u-scrollbar--hide u-min-height--100vh-124 d-flex align-items-center justify-content-center flex-grow-1'>
              <Spinner animation='border' variant='success' />
            </section>
          ) : (
            <section className='u-scrollbar--hide u-min-height--100vh-124 d-flex align-items-center justify-content-center flex-grow-1'>
              <Spinner animation='border' variant='success' />
            </section>
          )
        }
      >
        <Outlet />
      </Suspense>
    </Layout>
  ) : (
    <Navigate to='/signin' replace />
  )
}

export default ProtectedRoutes
