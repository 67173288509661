import { SET_CHOICE } from '../actions/types'

const initialState = {
  choice: 'simple'
}

const choiceReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_CHOICE:
      console.log(payload)
      return {
        ...state,
        // choice: payload.data.choice
        choice: action.payload
      }

    default:
      return state
  }
}

export default choiceReducer
