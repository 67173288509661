import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Stack from 'react-bootstrap/Stack'
import Footer from './Footer'
import Header from './Header/index'
import LeftSidebar from './LeftSidebar'
import { getSettings } from 'redux/actions/global'

const Layout = props => {
  const dispatch = useDispatch()
  const { user: authDetails, isLoggedIn } = useSelector(state => state.auth)
  const location = useLocation()
  const currentUrl = location.pathname
  const leftSidebarVisibleUrls = [
    '/dashboard',
    '/who-are-we-financially',
    '/own-your-future',
    '/what-is-your-financial-health',
    '/tell-your-money-where-to-go',
    '/build-your-roadmap',
    '/your-financial-world-on-one-page',
    '/thing-you-donot-want-to-thing-about',
    '/financial-summary',
    '/my-profile',
    '/edit-my-profile'
  ]
  const leftSidebarNotVisibleUrls = [
    '/',
    '/about-us',
    '/how-it-works',
    '/contact-us',
    '/about-us'
  ]
  const isLeftSidebarVisibleUrls = leftSidebarVisibleUrls.includes(currentUrl)
  const isLeftSidebarNotVisibleUrls =
    leftSidebarNotVisibleUrls.includes(currentUrl)

  useEffect(() => {
    // call settings api and store in redux store
    dispatch(getSettings())
  }, [authDetails, dispatch])

  return (
    <Fragment>
      <Header authDetails={authDetails} isLoggedIn={isLoggedIn} />
      <main style={{ minHeight: 'calc(100vh - 92px)'}}>
      {isLoggedIn && !isLeftSidebarNotVisibleUrls && (
        <Stack
          direction='horizontal'
          className='u-background--color-info-1-3 u-padding--t-92 u-padding--b-35 u-scrollbar--hide u-min-height--100vh-124 align-items-start gap-lg-0 gap-xl-4'
        >
          <LeftSidebar className='u-max-width--228 u-flex--228 u-height--100vh-124 overflow-y-auto overflow-x-hidden d-none d-lg-block' />
          {props.children}
        </Stack>
      )}
      {!isLeftSidebarVisibleUrls && !isLoggedIn && props.children}
      {isLoggedIn && isLeftSidebarNotVisibleUrls && props.children}
      </main>
      <Footer />
    </Fragment>
  )
}

export default Layout
