import axios from 'axios'
import { authHeader } from './auth-header'

const get = (id, step, subStep) => {
  return axios.get(process.env.REACT_APP_API_URL + `getSteps/${id}`, {
    headers: authHeader(),
    params: {
      step,
      page: subStep
    }
  })
}
const updateAssessment = (id) => {
  return axios.get(process.env.REACT_APP_API_URL + `update-assesment/${id}`, {
    headers: authHeader()
  })
}
const updateSkippedSteps = (id,data) => {
  return axios.post(process.env.REACT_APP_API_URL + `steps/skippedStepsAndPage/${id}`,data, {
    headers: authHeader()
  })
}

const downloadPdf = (id, formData) => {
  return axios.post(
    process.env.REACT_APP_API_URL + `wealthLanguagePdf/${id}`,
    formData,
    {
      headers: authHeader()
    }
  )
}

const downloadBalanceSheetPdf = id => {
  return axios.get(process.env.REACT_APP_API_URL + `getBalanceSheetPdf/${id}`, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return axios.post(process.env.REACT_APP_API_URL + `steps/${id}`, data, {
    headers: authHeader()
  })
}

const createActionItem = (id, data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + `questions/actionItems/${id}`,
    data,
    {
      headers: authHeader()
    }
  )
}


const generateRefreshToken = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + `generateRefreshToken`,
    data,
    {
      headers: authHeader()
    }
  )
}


const stepService = {
  get,
  downloadPdf,
  downloadBalanceSheetPdf,
  update,
  createActionItem,
  generateRefreshToken,
  updateAssessment,
  updateSkippedSteps
}
export default stepService
