import React, { useState, useEffect, lazy, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import Offcanvas from 'react-bootstrap/Offcanvas'

import ArkosLogo from 'assets/images/home/header/arkos-logo.svg'
import LeftSidebar from '../LeftSidebar/index'
import "./style.css"

const UserBox = lazy(() => import('./UserBox'))

const Header = props => {
  const location = useLocation()
  const allowedUrls = ['/', '/about-us', '/contact-us', '/how-it-works']
  const currentUrl = location.pathname
  // Check if the current URL is in the allowed URLs array
  const isUrlAllowed = allowedUrls.includes(currentUrl)
  const [scrollBackground, setScrollBackground] = useState('')
  const className = `bg-white z-3 u-box-shadow--color-black-12p`
  const commonUrls = [
    '/dashboard',
    '/who-are-we-financially',
    '/own-your-future',
    '/what-is-your-financial-health',
    '/tell-your-money-where-to-go',
    '/build-your-roadmap',
    '/your-financial-world-on-one-page',
    '/thing-you-donot-want-to-thing-about',
    '/financial-summary',
    '/my-profile',
    '/edit-my-profile'
  ]
  const stretchHeaderAllowedUrls = commonUrls
  const isStretchHeaderAllowedUrls =
    stretchHeaderAllowedUrls.includes(currentUrl)
  const leftSidebarVisibleUrls = commonUrls
  const isLeftSidebarVisibleUrls = leftSidebarVisibleUrls.includes(currentUrl)

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  const scrollHandler = () => {
    if (window.scrollY > 0) {
      setScrollBackground(className)
    } else {
      setScrollBackground('bg-transparent')
    }
  }

  return (
    <header
      className={`z-999 position-fixed top-0 start-0 w-100 u-padding--y-14 ${
        !props.isLoggedIn ? 'u-margin--b-21' : ''
      } ${scrollBackground} s-navbar--header ${
        isStretchHeaderAllowedUrls ? 'u-padding--x-16' : ''
      }`}
    >
      <Stack direction='horizontal' className='gap-0 gap-md-3'>
        {props.isLoggedIn && (
          <Navbar key={false} expand={false} className='d-flex d-lg-none py-0 nav-right'>
            <Container className='px-0' fluid>
              <Navbar.Toggle
                aria-controls='offcanvasNavbar-expand-false'
                className='me-0 fs-6'
              />
              <Navbar.Offcanvas
                id='offcanvasNavbar-expand-false'
                aria-labelledby='offcanvasNavbarLabel-expand-false'
                placement='end'
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id='offcanvasNavbarLabel-expand-false'></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0'>
                  <LeftSidebar className='w-100' />
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        )}
        <Navbar expand='lg' className='py-0 flex-grow-1 main-header-top'>
          <Container fluid={isStretchHeaderAllowedUrls}>
            <Navbar.Brand
              as={Link}
              to='/'
              className='d-flex align-items-center justify-content-center u-max-width--169 u-flex--169 py-0 me-0 me-md-3'
            >
              <Image src={ArkosLogo} alt='Arkos logo' />
            </Navbar.Brand>
            {/* {isUrlAllowed && ( */}
              <Fragment>
                <Navbar.Toggle
                  aria-controls='basic-navbar-nav'
                  className='fs-6'
                />
                <Navbar.Collapse
                  id='basic-navbar-nav'
                  className='justify-content-end pb-3 pb-lg-0'
                >
                  <Nav className='u-margin--x-m-12'>
                    <Nav.Link
                      as={Link}
                      to='/'
                      className='u-font-size--15 fw-semibold u-text-color--color-primary-1-1 text-decoration-none text-uppercase u-padding--x-12 py-3 py-lg-0 d-flex align-items-center justify-content-lg-center'
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to='/how-it-works'
                      className='u-font-size--15 fw-semibold u-text-color--color-primary-1-1 text-decoration-none text-uppercase u-padding--x-12 py-3 py-lg-0 d-flex align-items-center justify-content-lg-center'
                    >
                      How it works
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to='/contact-us'
                      className='u-font-size--15 fw-semibold u-text-color--color-primary-1-1 text-decoration-none text-uppercase u-padding--x-12 py-3 py-lg-0 d-flex align-items-center justify-content-lg-center'
                    >
                      Contact Us
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to='/about-us'
                      className='u-font-size--15 fw-semibold u-text-color--color-primary-1-1 text-decoration-none text-uppercase u-padding--x-12 py-3 py-lg-0 d-flex align-items-center justify-content-lg-center'
                    >
                      About Us
                    </Nav.Link>

                    {!props.isLoggedIn && isUrlAllowed &&(
                      <Nav.Link
                        as={Link}
                        to='/signin'
                        className='u-padding--x-12 py-3 py-lg-0 d-flex align-items-center justify-content-center'
                      >
                        <Button className='u-background--color-orange-1-1 u-border-color--color-orange-1-1 u-width--105 u-height--39 u-font-size--15 fw-medium text-uppercase d-flex align-items-center justify-content-center'>
                          Sign In
                        </Button>
                      </Nav.Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Fragment>
            {/* )} */}
            {props.isLoggedIn && <UserBox authDetails={props.authDetails} />}
          </Container>
        </Navbar>
      </Stack>
    </header>
  )
}

export default Header
