import {
  RETRIEVE_LOGGEDIN_USER,
  CREATE_USER,
  UPDATE_USER,
  CHANGE_PASSWORD
} from '../actions/types'

const initialState = {}

const userReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RETRIEVE_LOGGEDIN_USER:
      return payload

    case CREATE_USER:
      return payload

    case UPDATE_USER:
      return payload

    case CHANGE_PASSWORD:
      return payload

    default:
      return state
  }
}

export default userReducer
