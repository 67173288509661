import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './App.scss'
import Main from 'components/Layout/Main'
import store from 'redux/store'
import { Provider } from 'react-redux'
import ChatBot from 'components/ChatBot'

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer />
        {/* <ChatBot /> */}
        <Main />
      </BrowserRouter>
    </Provider>
  )
}

export default App
