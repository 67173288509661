import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Stack } from 'react-bootstrap';
import './CustomNavigationButtons.css'; 
import {  useSelector } from 'react-redux'
import StepService from 'redux/services/step.service'
const CustomNavigationButtons = ({ previousLink, nextLink, nextOnClick, loading, nextButtonLabel, showPrevious, showSkip, showSaveAndNext }) => {
const { user: authDetails } = useSelector(state => state.auth)
  const getStepAndPage = (nextLink) => {
    let page='';
    let step='';
    switch (nextLink){
      case '/steps/step-one/page-three':
        page='2';
        step='1';
        break;
        case '/steps/step-one/page-four':
        page='3';
        step='1';
        break;
        case '/steps/step-one/page-five':
        page='4';
        step='1';
        break;
        case '/steps/step-one/page-six':
        page='5';
        step='1';
        break;
        case '/steps/step-one/page-seven':
        page='6';
        step='1';
        break;
        case '/steps/step-one/page-eight':
        page='7';
        step='1';
        break;
        case '/steps/step-two/page-one':
        page='8';
        step='1';
        break;
        case '/steps/step-two/page-five':
        page='3';
        step='2';
        break;
        case '/steps/step-two/page-four':
        page='2';
        step='2';
        break;
        case '/steps/step-three/page-one':
        page='5';
        step='2';
        break;
        case '/steps/step-three/page-two':
        page='1';
        step='3';
        break;
        case '/steps/step-three/page-three':
        page='2';
        step='3';
        break;
        case '/steps/step-three/page-four':
        page='3';
        step='3';
        break;
        case '/steps/step-six/page-one':
        page='0';
        step='5';
        break;
        case '/steps/step-six/page-two':
        page='1';
        step='6';
        break;
        case '/steps/step-six/page-three':
        page='2';
        step='6';
        break;
        default:
        page='';
        step='';
    }
    return {page,step}
  }
    const handleSkipClick = async() => {
    
    // Get nextLink value
    
    const data=await getStepAndPage(nextLink);
    if(data.page!='' && data.step!=''){
//Call API
StepService.updateSkippedSteps(authDetails?.id,data).then(
  response => {
    console.log('response', response)
  },
  error => {
    const errorMsg =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString()
  }
)
    }
  };

  return (
    <div className="custom-navigation-buttons-container">
      <Row className='u-margin--t-35 gap-4 gap-lg-0'>
        <Col lg={6}>
          {showPrevious &&
            <Button
              as={Link}
              to={previousLink}
              variant='outline-secondary'
              className='u-width--124 u-padding--x-25 u-height--51 u-font-size--15 fw-medium text-uppercase border-2 u-border-color--color-success-1-2 rounded-3 d-flex align-items-center justify-content-center text-center'
            >
              Previous
            </Button>
          }
        </Col>
        <Col lg={6}>
          <Stack className='flex-column flex-lg-row justify-content-center justify-content-lg-end gap-4 gap-lg-3'>
            {showSkip &&
              <Button
                as={Link}
                to={nextLink}
                onClick={handleSkipClick}
                variant='outline-secondary'
                className='u-width--112 u-padding--x-25 u-height--51 u-font-size--15 fw-medium text-uppercase border-2 u-border-color--color-success-1-2 rounded-3 d-flex align-items-center justify-content-center text-center'
              >
                Skip
              </Button>
            }
            {showSaveAndNext && (
              <Button
                onClick={nextOnClick}
                disabled={loading}
                className='u-width--200 u-background--color-orange-1-1 u-border-color--color-orange-1-1 u-padding--x-25 u-height--51 u-font-size--15 fw-medium text-uppercase d-flex align-items-center justify-content-center text-center rounded-3'
              >
                {nextButtonLabel}
              </Button>
            )}
          </Stack>
        </Col>
      </Row>
    </div>
  );
};

export default CustomNavigationButtons;
