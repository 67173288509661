import { GET_SETTINGS } from './types'

import globalService from 'redux/services/global.service'

export const getSettings = () => async dispatch => {
  try {
    const res = await globalService.getSettings()

    dispatch({
      type: GET_SETTINGS,
      payload: res
    })

    return Promise.resolve(res.data)
  } catch (err) {
    return Promise.reject(err)
  }
}
