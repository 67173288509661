import axios from 'axios'
import { authHeader } from './auth-header'

// const get = (id, step, subStep) => {
//     return axios.get(process.env.REACT_APP_API_URL + `getSteps/${id}`, {
//       headers: authHeader(),
//       params: {
//         step,
//         page: subStep
//       },
//     });
// };

const getSettings = () => {
  return axios.get(process.env.REACT_APP_API_URL + `settings/list`, {
    headers: authHeader()
  })
}

const globalService = { getSettings }
export default globalService
