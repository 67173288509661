import { GET_SETTINGS } from '../actions/types'

const initialState = {
  listSettings: []
}

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_SETTINGS:
      return {
        ...state,
        listSettings: payload.data.listSettings
      }

    default:
      return state
  }
}

export default notificationReducer
