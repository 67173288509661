// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle::after {
  display: none;
}
.btn .badge {
  position: relative;
  top: -13px;
}
.btn {
  border: none;
}

.z-999 {
  z-index: 999 !important;
}

.nav-right .navbar-toggler {
  border: none;
  padding: 0px;
  box-shadow: none;
  padding-left: 10px;
}
.main-header-top .navbar-toggler {
  order: 3;
  padding: 0px;
  border: 0px;
}
.nav-right .navbar-toggler:after {
  content: '\\2807';
  font-size: 25px;
}
.nav-right span.navbar-toggler-icon {
  display: none;
}

@media(max-width:767px){
 .s-dropdown--user-profile-dropdown{
  h1{
    font-size: 0px;
  }
  h2{
    font-size: 0px !important;
  }
 }
}
@media (max-width: 576px){
.u-flex--169 {
    flex: 0 0 5.1875rem !important;
}
}
@media (max-width: 492px)
{
  .responsive-header
  {
    /* width: 100%; */
    justify-content: space-between;
    /* margin-top: 20px; */
    padding-right: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Header/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,QAAQ;EACR,YAAY;EACZ,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,aAAa;AACf;;AAEA;CACC;EACC;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;CACD;AACD;AACA;AACA;IACI,8BAA8B;AAClC;AACA;AACA;;EAEE;;IAEE,iBAAiB;IACjB,8BAA8B;IAC9B,sBAAsB;IACtB,mBAAmB;EACrB;AACF","sourcesContent":[".dropdown-toggle::after {\n  display: none;\n}\n.btn .badge {\n  position: relative;\n  top: -13px;\n}\n.btn {\n  border: none;\n}\n\n.z-999 {\n  z-index: 999 !important;\n}\n\n.nav-right .navbar-toggler {\n  border: none;\n  padding: 0px;\n  box-shadow: none;\n  padding-left: 10px;\n}\n.main-header-top .navbar-toggler {\n  order: 3;\n  padding: 0px;\n  border: 0px;\n}\n.nav-right .navbar-toggler:after {\n  content: '\\2807';\n  font-size: 25px;\n}\n.nav-right span.navbar-toggler-icon {\n  display: none;\n}\n\n@media(max-width:767px){\n .s-dropdown--user-profile-dropdown{\n  h1{\n    font-size: 0px;\n  }\n  h2{\n    font-size: 0px !important;\n  }\n }\n}\n@media (max-width: 576px){\n.u-flex--169 {\n    flex: 0 0 5.1875rem !important;\n}\n}\n@media (max-width: 492px)\n{\n  .responsive-header\n  {\n    /* width: 100%; */\n    justify-content: space-between;\n    /* margin-top: 20px; */\n    padding-right: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
