import { Routes, Route, useLocation } from 'react-router-dom'
import React, { useEffect, lazy } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearMessage } from 'redux/actions/message'

import ProtectedRoutes from 'routes/ProtectedRoutes'
import AnonymousRoutes from 'routes/AnonymousRoutes'
import PublicRoutes from 'routes/PublicRoutes'
import StepTwoPageOneAndHalf from 'components/Steps/StepTwo/PageOneAndHalf'

//const NotFound = lazy(() => import('../../NotFound/index'));
const Home = lazy(() => import('components/Home/index'))
const HowItWorks = lazy(() => import('components/HowItWorks/index'))
const AboutUs = lazy(() => import('components/AboutUs/index'))
const ContactUs = lazy(() => import('components/ContactUs/index'))
const MyProfile = lazy(() => import('components/MyProfile/index'))
const EditMyProfile = lazy(() => import('components/MyProfile/EditMyProfile'))
const Dashboard = lazy(() => import('components/Dashboard/index'))
const PrivacyPolicy = lazy(() => import('components/PrivacyPolicy/index'))
const TermsAndConditions = lazy(() => import('components/TermsAndConditions/index'))
const WhoAreWeFinancially = lazy(
  () => import('components/WhoAreWeFinancially/index')
)
const OwnYourFuture = lazy(() => import('components/OwnYourFuture/index'))
const WhatIsYourFinancialHealth = lazy(
  () => import('components/WhatIsYourFinancialHealth/index')
)
const TellYourMoneyWhereToGo = lazy(
  () => import('components/TellYourMoneyWhereToGo/index')
)
const BuildYourRoadmap = lazy(() => import('components/BuildYourRoadmap/index'))
const YourFinancialWorldOnOnePage = lazy(
  () => import('components/YourFinancialWorldOnOnePage/index')
)
const ThingYouDonotWantToThingAbout = lazy(
  () => import('components/ThingYouDonotWantToThingAbout/index')
)
const FinancialSummary = lazy(() => import('components/FinancialSummary/index'))
const StepOnePageOne = lazy(
  () => import('components/Steps/StepOne/PageOne/index')
)
const StepOnePageTwo = lazy(
  () => import('components/Steps/StepOne/PageTwo/index')
)
const StepOnePageThree = lazy(
  () => import('components/Steps/StepOne/PageThree/index')
)
const StepOnePageFour = lazy(
  () => import('components/Steps/StepOne/PageFour/index')
)
const StepOnePageFive = lazy(
  () => import('components/Steps/StepOne/PageFive/index')
)
const StepOnePageSix = lazy(
  () => import('components/Steps/StepOne/PageSix/index')
)
const StepOnePageSeven = lazy(
  () => import('components/Steps/StepOne/PageSeven/index')
)
const StepOnePageEight = lazy(
  () => import('components/Steps/StepOne/PageEight/index')
)
const StepTwoPageOne = lazy(
  () => import('components/Steps/StepTwo/PageOne/index')
)
const StepTwoPageTwo = lazy(
  () => import('components/Steps/StepTwo/PageTwo/index')
)
const StepTwoPageThree = lazy(
  () => import('components/Steps/StepTwo/PageThree/index')
)
const StepTwoPageFour = lazy(
  () => import('components/Steps/StepTwo/PageFour/index')
)
const StepTwoPageFive = lazy(
  () => import('components/Steps/StepTwo/PageFive/index')
)
const StepThreePageOne = lazy(
  () => import('components/Steps/StepThree/PageOne/index')
)
const StepThreePageTwo = lazy(
  () => import('components/Steps/StepThree/PageTwo/index')
)
const StepThreePageThree = lazy(
  () => import('components/Steps/StepThree/PageThree/index')
)
const StepThreePageFour = lazy(
  () => import('components/Steps/StepThree/PageFour/index')
)
const StepFour = lazy(() => import('components/Steps/StepFour'))
const StepFive = lazy(() => import('components/Steps/StepFive'))
const StepSixPageOne = lazy(
  () => import('components/Steps/StepSix/PageOne/index')
)
const StepSixPageTwo = lazy(
  () => import('components/Steps/StepSix/PageTwo/index')
)
const StepSixPageThree = lazy(
  () => import('components/Steps/StepSix/PageThree/index')
)
const StepSeven = lazy(() => import('components/Steps/StepSeven'))
const SignIn = lazy(() => import('components/SignIn/index'))
const SignUp = lazy(() => import('components/SignUp/index'))
const ForgotPassword = lazy(() => import('components/ForgotPassword/index'))
const ResetPassword = lazy(() => import('components/ResetPassword/index'))
const Step1Result = lazy(() => import('components/Results/step1'))
const Step2Result = lazy(() => import('components/Results/step2'))
const Step3Result = lazy(() => import('components/Results/step3'))

const Main = () => {
  let isLoggedIn = false

  const { user: currentUser } = useSelector(state => state.auth)

  if (currentUser && currentUser.roleId === 3) {
    isLoggedIn = true
  }
  const dispatch = useDispatch()

  const location = useLocation()

  useEffect(() => {
    if (['/signin'].includes(location.pathname)) {
      dispatch(clearMessage()) // clear message when changing location
    }
  }, [dispatch, location])

  return (
    <Routes>
      <Route element={<ProtectedRoutes isLoggedIn={isLoggedIn} />}>
        <Route path='/my-profile' key='my-profile' element={<MyProfile />} />
        <Route path='/dashboard' key='dashboard' element={<Dashboard />} />
        <Route path='/step1result' key='dashboard' element={<Step1Result />} />
        <Route path='/step2result' key='dashboard' element={<Step2Result />} />
        <Route path='/step3result' key='dashboard' element={<Step3Result />} />
        <Route
          path='/who-are-we-financially'
          key='who-are-we-financially'
          element={<WhoAreWeFinancially />}
        />
        <Route
          path='/own-your-future'
          key='own-your-future'
          element={<OwnYourFuture />}
        />
        <Route
          path='/what-is-your-financial-health'
          key='what-is-your-financial-health'
          element={<WhatIsYourFinancialHealth />}
        />
        <Route
          path='/tell-your-money-where-to-go'
          key='tell-your-money-where-to-go'
          element={<TellYourMoneyWhereToGo />}
        />
        <Route
          path='/build-your-roadmap'
          key='build-your-roadmap'
          element={<BuildYourRoadmap />}
        />
        <Route
          path='/your-financial-world-on-one-page'
          key='your-financial-world-on-one-page'
          element={<YourFinancialWorldOnOnePage />}
        />
        <Route
          path='/edit-my-profile'
          key='edit-my-profile'
          element={<EditMyProfile />}
        />
        <Route
          path='/thing-you-donot-want-to-thing-about'
          key='thing-you-donot-want-to-thing-about'
          element={<ThingYouDonotWantToThingAbout />}
        />
        <Route
          path='/financial-summary'
          key='financial-summary'
          element={<FinancialSummary />}
        />
        <Route
          path='/steps/step-one/page-one'
          key='step-one-page-one'
          element={<StepOnePageOne />}
        />
        <Route
          path='/steps/step-one/page-two'
          key='step-one-page-two'
          element={<StepOnePageTwo />}
        />
        <Route
          path='/steps/step-one/page-three'
          key='step-one-page-three'
          element={<StepOnePageThree />}
        />
        <Route
          path='/steps/step-one/page-four'
          key='step-one-page-four'
          element={<StepOnePageFour />}
        />
        <Route
          path='/steps/step-one/page-five'
          key='step-one-page-five'
          element={<StepOnePageFive />}
        />
        <Route
          path='/steps/step-one/page-six'
          key='step-one-page-six'
          element={<StepOnePageSix />}
        />
        <Route
          path='/steps/step-one/page-seven'
          key='step-one-page-seven'
          element={<StepOnePageSeven />}
        />
        <Route
          path='/steps/step-one/page-eight'
          key='step-one-page-eight'
          element={<StepOnePageEight />}
        />
        <Route
          path='/steps/step-two/page-one'
          key='step-two-page-one'
          element={<StepTwoPageOne />}
        />
        <Route
          path='/steps/step-two/page-one-and-half'
          key='step-two-page-one-and-half'
          element={<StepTwoPageOneAndHalf />}
        />
        <Route
          path='/steps/step-two/page-two'
          key='step-two-page-two'
          element={<StepTwoPageTwo />}
        />
        <Route
          path='/steps/step-two/page-three'
          key='step-two-page-three'
          element={<StepTwoPageThree />}
        />
        <Route
          path='/steps/step-two/page-four'
          key='step-two-page-four'
          element={<StepTwoPageFour />}
        />
        <Route
          path='/steps/step-two/page-five'
          key='step-two-page-five'
          element={<StepTwoPageFive />}
        />
        <Route
          path='/steps/step-three/page-one'
          key='step-three-page-one'
          element={<StepThreePageOne />}
        />
        <Route
          path='/steps/step-three/page-two'
          key='step-three-page-two'
          element={<StepThreePageTwo />}
        />
        <Route
          path='/steps/step-three/page-three'
          key='step-three-page-three'
          element={<StepThreePageThree />}
        />
        <Route
          path='/steps/step-three/page-four'
          key='step-three-page-four'
          element={<StepThreePageFour />}
        />
        <Route path='/steps/step-four' key='step-four' element={<StepFour />} />
        <Route path='/steps/step-five' key='step-five' element={<StepFive />} />
        <Route
          path='/steps/step-six/page-one'
          key='step-six-page-one'
          element={<StepSixPageOne />}
        />
        <Route
          path='/steps/step-six/page-two'
          key='step-six-page-two'
          element={<StepSixPageTwo />}
        />
        <Route
          path='/steps/step-six/page-three'
          key='step-six-page-three'
          element={<StepSixPageThree />}
        />
        <Route
          path='/steps/step-seven'
          key='step-seven'
          element={<StepSeven />}
        />
      </Route>

      <Route element={<AnonymousRoutes isLoggedIn={isLoggedIn} />}>
        <Route path='/' key='/' element={<Home />} />
        <Route
          path='/how-it-works'
          key='how-it-works'
          element={<HowItWorks />}
        />
        <Route path='/about-us' key='about-us' element={<AboutUs />} />
        <Route path='/contact-us' key='contact-us' element={<ContactUs />} />
        <Route path='/privacy-policy' key='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-and-conditions' key='terms-and-conditions' element={<TermsAndConditions />} />
      </Route>

      <Route
        element={
          <PublicRoutes
            isLoggedIn={isLoggedIn}
            stepAt={currentUser?.stepAt}
            subStepAt={currentUser?.subStepAt}
          />
        }
      >
        <Route path='/signin' key='signin' element={<SignIn />} />
        <Route path='/signup' key='signup' element={<SignUp />} />
        <Route
          path='/forgot-password'
          key='forgot-password'
          element={<ForgotPassword />}
        />
        <Route
          path='/reset-password'
          key='reset-password'
          element={<ResetPassword />}
        />
      </Route>

      {/* Catch-all route for 404 */}
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  )
}

export default Main
