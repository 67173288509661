// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-line {
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 1024px){
    .u-margin--x-m-66 {
        margin-right: -2.25rem !important;
        margin-left: -2.25rem !important;
    }
}

@media (max-width:560px){
    .u-margin--x-m-66 {
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding: 0px;
    }
    /* .step-value {
        left: 93% !important;
    } */
}  `, "",{"version":3,"sources":["webpack://./src/common/ProgressComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI;QACI,iCAAiC;QACjC,gCAAgC;IACpC;AACJ;;AAEA;IACI;QACI,0BAA0B;QAC1B,yBAAyB;QACzB,YAAY;IAChB;IACA;;OAEG;AACP","sourcesContent":[".step-line {\n    padding-left: 20px;\n    padding-right: 20px;\n}\n\n@media (max-width: 1024px){\n    .u-margin--x-m-66 {\n        margin-right: -2.25rem !important;\n        margin-left: -2.25rem !important;\n    }\n}\n\n@media (max-width:560px){\n    .u-margin--x-m-66 {\n        margin-right: 0 !important;\n        margin-left: 0 !important;\n        padding: 0px;\n    }\n    /* .step-value {\n        left: 93% !important;\n    } */\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
